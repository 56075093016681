import React, {useState, useEffect} from "react";
import './styles.css'; // Make sure to include your custom styles

export const Footer = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsVisible(window.scrollY > 100);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        const scrollStep = -window.scrollY / (1500 / 15); // Determines speed of scrolling, adjust the divisor for different speed

        const scrollAnimation = () => {
            if (window.scrollY !== 0) {
                window.requestAnimationFrame(scrollAnimation);
                window.scrollTo(0, window.scrollY + scrollStep);
            }
        };

        scrollAnimation();
    };

    return (
        <div>
            {/* Footer Start */}
            <div className="container-fluid hero-header bg-primary py-5 mt-5 text-white">
                <div className="container py-5">
                    <div className="row g-5 ">
                        <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
                            <a href="/" className="d-inline-block mb-3">
                                <h2 className="text-white">Vibes of Herbs</h2>
                            </a>
                            <p className="mb-0">Vibes of Herbs is the perfect place to buy your beauty & healthcare
                                products. Our history has paved the way to your trust. Join with us immediately!</p>
                        </div>
                        <div className="col-md-6 col-lg-4 wow fadeIn d-inline-flex flex-column align-items-start"
                             data-wow-delay="0.3s">
                            <h5 className="mb-4" style={{color:'#e7922e'}}>Get In Touch</h5>
                            <div className='d-inline-flex flex-column  align-items-start'>
                                <div className='d-flex '>
                                    <i className="fa fa-map-marker-alt me-3 mt-2"></i>
                                    <div className='d-flex align-items-start justify-content-start'>
                                        <p className='text-start'>"Ruwasampatha" Weliweriya, Gampaha, Sri Lanka</p>
                                    </div>


                                </div>
                                <div className='d-flex '>
                                    <i className="fa fa-phone-alt me-3 mt-2"></i>
                                    <div className='d-flex align-items-start justify-content-start'>
                                    <p className='text-start '>+94 33 225 6108 | +94 777 10 2250</p>
                                    </div>
                                </div>
                                <div className='d-flex '>
                                    <i className="fa fa-envelope me-3 mt-2"></i>
                                    <div className='d-flex align-items-start justify-content-start'>
                                    <p className='text-start'>info@vibesofherbs.com</p>
                                    </div>
                                </div>
                                <div className='d-flex '>
                                    <i className="fa fa-globe me-3 mt-1"></i>
                                    <div className='d-flex align-items-start justify-content-start'>
                                        <a href="https://ruwasampatha.com/" target='_blank' className=" text-start text-white">www.ruwasampatha.com</a>

                                    </div>
                                </div>
                                <div className="social-icons d-flex pt-2 gap-4 ">
                                    <a className="btn btn-square btn-outline-light me-1 mb-2" href=""><i
                                        className="fab fa-twitter"></i></a>
                                    <a className="btn btn-square btn-outline-light me-1 mb-2" href=""><i
                                        className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-square btn-outline-light me-1 mb-2" href=""><i
                                        className="fab fa-instagram"></i></a>
                                    <a className="btn btn-square btn-outline-light me-1 mb-2" href=""><i
                                        className="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>

                        </div>
                        <div className='col-md-12 col-lg-5'>
                            <div className='row g-5'>
                                <div className="col-md-6 col-lg-6 wow fadeIn d-inline-flex flex-column align-items-start"
                                     data-wow-delay="0.5s">
                                    <h5 className="mb-4" style={{color:'#e7922e'}}>Our Products</h5>
                                    <div className='d-inline-flex flex-column  align-items-start'>
                                        <a className="btn btn-link text-white hover-effect"  style={{textAlign:'justify'}} href="/product">Hair Wax</a>
                                        <a className="btn btn-link text-white hover-effect"  style={{textAlign:'justify'}} href="/product" >Lip Balm</a>
                                        <a className="btn btn-link text-white hover-effect"  style={{textAlign:'justify'}} href="/product" >Body Scrub</a>
                                        <a className="btn btn-link text-white hover-effect" style={{textAlign:'justify'}} href="/product">Moisturizing Lotion</a>
                                    </div>

                                    {/* <a className="btn btn-link text-white hover-effect" href="">Anti smell Shampoo</a> */}
                                </div>
                                <div className="col-md-6 col-lg-6 wow fadeIn d-flex flex-column align-items-start"  data-wow-delay="0.7s">
                                    <h5 className="mb-4" style={{color:'#e7922e'}}>Popular Link</h5>
                                    <div className='d-inline-flex flex-column  align-items-start'>
                                        <a className="btn btn-link text-white hover-effect" href="/about">About Us</a>
                                        <a className="btn btn-link text-white hover-effect" href="/history">Story</a>

                                        <a className="btn btn-link text-white hover-effect" href="/product">Product</a>
                                        <a className="btn btn-link text-white hover-effect" href="/blog">Blog Article</a>
                                        <a className="btn btn-link text-white hover-effect" href="/contact">Contact</a>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div className="container wow fadeIn" data-wow-delay="0.1s">
                    <div className="copyright">
                        <div className="row">
                            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                                &copy; <a className="text-white" href="#">Vibes of Herbs</a>, All Right Reserved.
                                Designed By
                                {/* <!--*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. ***--> */}
                                <a className=" text-white" target="_blank" href="https://loopyholdings.com/"> Loopy Holdings </a> 
                            </div>
                            <div className="col-md-6 text-center text-md-end">
                                <div className="footer-menu">
                                    <a className="text-white hover-effect" href="/">Home</a>
                                    <a className="text-white hover-effect" href="">Cookies</a>
                                    <a className="text-white hover-effect" href="">Help</a>
                                    <a className="text-white hover-effect" href="">FAQs</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Footer End */}

            {/* Back to Top Button */}
            {isVisible && (
                <button className="btn btn-lg btn-primary btn-lg-square back-to-top" onClick={scrollToTop}>
                    <i className="bi bi-arrow-up"></i>
                </button>
            )}
        </div>
    )
}
