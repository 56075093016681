import React from "react";

export const Blog = () => {
    return (
        <div>

            {/* <!-- Hero Start -->*/}
            <div class="container-fluid bg-primary hero-header-blog mb-5">
                <div class="container text-center">
                    <h1 class="display-4 text-white mb-3 animated slideInDown">Blog Articles</h1>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb justify-content-center mb-0 animated slideInDown">
                            <li class="breadcrumb-item"><a class="text-white fw-bold" href="/">Home</a></li>
                            <li class="breadcrumb-item"><a class="text-white fw-bold" href="#">Pages</a></li>
                            <li class="breadcrumb-item text-white fw-bold active" aria-current="page">Blog Articles</li>
                        </ol>
                    </nav>
                </div>
            </div>
            
                    {/* <!-- Hero End -->  */}


            
          {/* <!-- Blog Start --> */}

            <div className="container-fluid py-5">
                <div className="container">
                    <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{ maxWidth: '600px' }}>
                        <h1 className="text-primary mb-3"><span className="fw-light text-dark">From Our</span> Blog Articles</h1>
                        <p className="mb-5">Discover the secrets of nature's beauty with our 100% Ayurvedic products, designed to nourish and rejuvenate your skin naturally. Embrace the power of herbs and botanicals for a radiant, healthy glow.</p>
                    </div>
                    <div className="row g-4 d-flex justify-content-center">
                        <div className="col-md-7 col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                            <div className="blog-item border  p-4">
                                <img className="img-fluid1 mb-4  " src="img/lipbalm.jpg" alt="Blog 1" />
                                <a href="" className="h5 lh-base d-inline-block">Nourish & Protect Lips With Ayurvedic Lip Balm</a>
                                <div className="d-flex text-black-50 mb-2">
                                    <div className="pe-3">
                                        <small className="fa fa-eye me-1"></small>
                                        <small>9999 Views</small>
                                    </div>
                                    <div className="pe-3">
                                        <small className="fa fa-comments me-1"></small>
                                        <small>9999 Comments</small>
                                    </div>
                                </div>
                                <p className="mb-4 d-flex" style={{textAlign:"justify",fontSize:'15px'}}>
                                    Ayurvedic lip balm is a natural and holistic product formulated using traditional Ayurvedic ingredients. It typically contains herbs, essential oils, and natural butters known for their healing, moisturizing, and soothing properties
                                    .</p>
                                <a href="" className="btn btn-outline-primary px-3">Read More</a>
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                            <div className="blog-item border  p-4">
                                <img className="img-fluid1 mb-4 " src="img/bodyscrub.jpg" alt="Blog 2" />
                                <a href="" className="h5 lh-base d-inline-block">Tried body exfoliation with an Ayurvedic body Scrub? </a>
                                <div className="d-flex text-black-50 mb-2">
                                    <div className="pe-3">
                                        <small className="fa fa-eye me-1"></small>
                                        <small>9999 Views</small>
                                    </div>
                                    <div className="pe-3">
                                        <small className="fa fa-comments me-1"></small>
                                        <small>9999 Comments</small>
                                    </div>
                                </div>
                                <p className="mb-4 d-flex" style={{textAlign:"justify",fontSize:'15px'}}>Body scrubbing is a great way to stimulate multiple senses. Body exfoliation can significantly improve the mood as the body mentally recognizes the performance of this self ritual.</p>
                                <a href="" className="btn btn-outline-primary px-3 ">Read More</a>
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                            <div className="blog-item border  p-4">
                                <img className="img-fluid1 mb-4 " src="img/bodylotion.jpg" alt="Blog 3" />
                                <a href="" className="h5 lh-base d-inline-block">Ayurvedic Moisturizers improve skin hydration!</a>
                                <div className="d-flex text-black-50 mb-2">
                                    <div className="pe-3">
                                        <small className="fa fa-eye me-1"></small>
                                        <small>9999 Views</small>
                                    </div>
                                    <div className="pe-3">
                                        <small className="fa fa-comments me-1"></small>
                                        <small>9999 Comments</small>
                                    </div>
                                </div>
                                <p className="mb-4 d-flex" style={{textAlign:"justify",fontSize:'15px'}}>Ayurvedic Moisturizers improves skin hydration and increases stratum corneum water content by directly providing water to the skin from their water phase and increasing occlusion to reduce trans-epidermal water loss, it also covers small skin fissures.

                                </p>
                                <a href="" className="btn btn-outline-primary px-3">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
          {/* <!-- Blog End --> */}


            {/* Newsletter Start */}
            {/* <div className="container-fluid newsletter bg-primary py-5 my-5">
                <div className="container py-5">
                    <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{ maxWidth: '600px' }}>
                        <h1 className="text-white mb-3"><span className="fw-light text-dark">Let's Subscribe</span> The Newsletter</h1>
                        <p className="text-white mb-4">Subscribe now to get 30% discount on any of our products</p>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-7 wow fadeIn" data-wow-delay="0.5s">
                            <div className="position-relative w-100 mt-3 mb-2">
                                <input className="form-control w-100 py-4 ps-4 pe-5" type="text" placeholder="Enter Your Email"
                                    style={{ height: '48px' }} />
                                <button type="button" className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"><i
                                    className="fa fa-paper-plane text-white fs-4"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* Newsletter End */}


        </div>
    )
}