import React from "react";

export const Page404 = () => {

    return (
        <div>

            {/* Hero Start */}
            <div className="container-fluid bg-primary hero-header mb-5">
                <div className="container text-center">
                    <h1 className="display-4 text-white mb-3 animated slideInDown">404 Error</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-center mb-0 animated slideInDown">
                            <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
                            <li className="breadcrumb-item"><a className="text-white" href="#">Pages</a></li>
                            <li className="breadcrumb-item text-white active" aria-current="page">404 Error</li>
                        </ol>
                    </nav>
                </div>
            </div>
            {/* Hero End */}

            {/* About Start */}
            <div className="container-fluid py-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container text-center">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <i className="bi bi-exclamation-triangle display-1 text-primary"></i>
                            <h1 className="display-1">404</h1>
                            <h1 className="mb-4">Page Not Found</h1>
                            <p className="mb-4">We’re sorry, the page you have looked for does not exist in our website! Maybe go to our home page or try to use a search?</p>
                            <a className="btn btn-primary py-2 px-4" href="/">Go Back To Home</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* About End */}

            {/* Newsletter Start */}
            {/* <div className="container-fluid newsletter bg-primary py-5 my-5">
                <div className="container py-5">
                    <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{ maxWidth: '600px' }}>
                        <h1 className="text-white mb-3"><span className="fw-light text-dark">Let's Subscribe</span> The Newsletter</h1>
                        <p className="text-white mb-4">Subscribe now to get 30% discount on any of our products</p>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-7 wow fadeIn" data-wow-delay="0.5s">
                            <div className="position-relative w-100 mt-3 mb-2">
                                <input className="form-control w-100 py-4 ps-4 pe-5" type="text" placeholder="Enter Your Email"
                                    style={{ height: '48px' }} />
                                <button type="button" className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"><i
                                    className="fa fa-paper-plane text-white fs-4"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* Newsletter End */}


        </div>
    )
}