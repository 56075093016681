import React, {useState} from "react";
import {Button, Card, Col, Container, Row} from "react-bootstrap";


export const History = () => {
    const awards = [
        "ENTREPRENEUR AWARDS 2020 – Western Province – Best Entrepreneur – Recovered from COVID related Business Issues - National Development Authority & NEDA with National Chamber",
        "ENTREPRENEUR AWARDS 2020 – Western Province – Best Enterprise – Industry / Manufacturing Sector (Medium Category) - National Development Authority & NEDA with National Chamber",
        "The Best Entrepreneur of the Year 2020 (2nd Runner Up) - National Development Authority & NEDA with National Chamber",
        "WOMEN ENTREPRENEUR – NATIONAL WINNER - SRI LANKAN ENTREPRENEUR OF THE YEAR 2019",
        "WOMEN ENTREPRENEUR – PROVINCIAL WINNER (WESTERN PROVINCE) - SRI LANKAN ENTREPRENEUR OF THE YEAR 2019",
        "Gold Winner (Cosmetic and Allied Industries) - National Industry Excellence Awards 2022",
        "1st place – Western Province – RDB Dhiri Abhimani 2021",
        "1st place – National - RDB Dhiri Abhimani 2021",
        "Gold Award – National level manufacturing sector – small category – CNCI Achiever Awards 2022",
        "WOMAN ENTREPRENEUR OF THE YEAR 2023 –In celebration of international women’s day – Voice of Asia Network (Siyatha TV)",
        "1ST RUNNER UP WOMEN ENTREPRENEUR - SRI LANKAN ENTEREPRENEUR OF THE YEAR 2018",
        "SILVER AWARD – CNCI Achiever AWARD 2019",
        "Potential Exporter- 27th Annual NCE Export Awards 2019",
        "Best Enterprise 2019 -Western Province / Industry & manufacturing sector –Medium category – National Development Authority &NEDA  with National Chamber ",
        "Women Entrepreneur Award 2019 - Medium category- Colombo School of Business & Management with WIF",
        "MERIT AWARDS – SWITCH – ASIA FOOD & BEVERAGE PROGRAMME (EU)"

    ];


    const [showAll, setShowAll] = useState(false);

    const handleReadMore = () => {
        setShowAll(!showAll);
    };

    const visibleAwards = showAll ? awards : awards.slice(0, 6);

    return (
        <>
            {/* Hero Start */}
            <div className="container-fluid bg-primary hero-header-story mb-5">
                <div className="container text-center">
                    <h1 className="display-4 text-white mb-3 animated slideInDown">Our Story</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-center mb-0 animated slideInDown">
                            <li className="breadcrumb-item"><a className="text-white fw-bold" href="/">Home</a></li>
                            <li className="breadcrumb-item"><a className="text-white fw-bold" href="#">Pages</a></li>
                            <li className="breadcrumb-item text-white active fw-bold" aria-current="page">Story</li>
                        </ol>
                    </nav>
                </div>
            </div>
            {/* Hero End */}

            {/*photo start*/}
            <div>

                <Container className="px-4" style={{marginTop: ''}}>
                    <Row className=" d-flex  g-2" style={{justifyContent:'center'}}>
                        <Col xs={12} sm={12} md={12} lg={8} className=" wow   "  data-wow-delay="0.1s"  >
                            <div className=" wow slideInUp d-flex" style={{justifyContent:'center'}} data-wow-delay="0.1s">
                                <img className="img-fluid animated pulse infinite" src="img/VOH1.png"
                                     style={{width: '50%'}}></img>
                            </div>
                                                  </Col>

                        <Col xs={12} sm={12} md={12} lg={8} className=" wow fadeIn  "  data-wow-delay="0.1s"  >


                            <h1 className="text-primary mb-3">  <span className="fw-light text-dark">Our Story </span></h1>
                            <p className=' d-flex para-history-upper '>
                                Edward Wickramarachchi, the founder of Wickramarachchi Laboratories, was passionate
                                about Ayurvedic medications since he was a child.
                                Born to Punchappuhamy Wickramarachchi and Evjin Nona wickramarachchi, Edward
                                Wickramarachchi was the eldest among seven siblings in the family.
                            </p>
                            <p className=' d-flex para-history-upper'>Wickramarachchi
                                family was renowned as an ancestral family of Ayurvedic medical, beauty and healthcare
                                production. The Grandfather, Father and Uncle of Edward Wickramarachchi were all
                                respected Ayurvedic doctors at their time.
                            </p>
                            <p className=' d-flex para-history-upper '>

                                As a family, they have done this services without charging any fees as they were a self
                                made family.
                                The uncle of Edward Wickramarachchi, known as “Gala Uda gedara Weda Mahatthaya”, was a
                                very famous Ayurvedic doctor and people from different areas of the country have visited
                                him for treatments.

                            </p>
                            <p className=' d-flex para-history-upper'>

                                With this strong family background, Edward Wickramarachchi had started doing experiments
                                himself and created many Ayurvedic products. He was very passionate about beauty &
                                cosmetic products as well.
                            </p>
                            <p className=' d-flex  para-history-upper '>
                                With this inspiration and dedication, he had planted the foundation for the famous
                                name “Wickramarachchi Laboratories”, a company that
                                has done a massive service to the Ayurvedic production of the country.
                            </p>
                            <p className='para-history-upper'>
                                “Many people appreciated my father’s efforts. It was amazing to be respected as that“
                                (Irosha Wickramarachchi)

                                Moving to the next generation, Irosha Wickramarachchi, the daughter of Edward
                                Wickramarachchi and Indra Wickramarachchi,
                                took hold of this great service with the inspiration she had from her ancestors.
                            </p>
                            <p className='para-history-upper'>
                                She was majorly inspired by seeing the respect that her father got from lots of people.
                                Through that, she was inspired to be a part of this great service, determining to go on
                                a long way of this
                                journey. She learned from her ancestors of different generations.
                            </p>
                            <p className='para-history-upper'>
                                She had a lots of innovative ideas and that paved the way for her to win many awards as
                                a great entrepreneur.

                                As a traditional Ayurvedic physician, Irosha Wickramarachchi wanted to bring this
                                service and production to a global level, and as a result of that “Vibes of Herbs” was
                                born.


                            </p>
                        </Col>

                    </Row>
                    <Row className="myGridContainer   g-2" >
                        {/*/////////*/}
                        <Col xs={12} sm={12} md={12} lg={6} className=" wow fadeIn  "  data-wow-delay="0.1s" >


                        </Col>
                    </Row>
                </Container>
                <Container className="">
                    <Row className="my-4 histoy-img ">
                        <Col xs={12} sm={4} md={4} className=" wow fadeIn" data-wow-delay="0.1s">
                            <img width='100%' style={{backgroundSize: 'cover', backgroundPosition: 'center'}}
                                 src='img/Vibes of Hearbs 002.jpg' alt='Kiriwehera'/>
                        </Col>
                        <Col xs={12} sm={4} md={4} className=" wow fadeIn" data-wow-delay="0.3s">
                            <img width='100%' style={{backgroundSize: 'cover', backgroundPosition: 'center'}}
                                 src='img/Vibes of Hearbs 003.jpg' alt='Kiriwehera'/>
                        </Col>
                        <Col xs={12} sm={4} md={4} className=" wow fadeIn" data-wow-delay="0.5s">
                            <img width='100%' style={{backgroundSize: 'cover', backgroundPosition: 'center'}}
                                 src='img/Vibes of Hearbs 001.jpg' alt='Kiriwehera'/>
                        </Col>
                    </Row>
                </Container>

                <Container className="mb-4 px-4  wow fadeIn" data-wow-delay="0.5s" style={{marginTop: '70px'}}>
                    <Row>
                        <Col>
                            <h1 className="text-primary mb-3"><span
                                className="fw-light text-dark">Awards and </span> Achievements</h1>
                        </Col>
                    </Row>
                    <Row>
                        {visibleAwards.map((award, index) => (
                            <Col xs={12} sm={12} md={12} lg={6} key={index} className="my-2 wow fadeIn"  data-wow-delay={`${0.3 * (index + 1)}s`}>
                                <Card className='card-hover'>
                                    <Card.Body>
                                        <Card.Text>{award}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <Row>
                        <Col className="text-center my-2">
                            <Button className="btn btn-dark  me-3 animated slideInRight" onClick={handleReadMore}>
                                {showAll ? "Read Less" : "Read More"}
                            </Button>
                        </Col>
                    </Row>

                </Container>
            </div>
            {/*photo end*/}

        </>
    )
}