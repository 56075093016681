import React from "react";

export const Product = () => {

    return (

        <div>

            {/* Hero Start */}
            <div className="container-fluid bg-primary hero-header-product mb-5">
                <div className="container text-center">
                    <h1 className="display-4 text-white mb-3 animated slideInDown">Products</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-center mb-0 animated slideInDown">
                            <li className="breadcrumb-item"><a className="text-white fw-bold" href="/">Home</a></li>
                            <li className="breadcrumb-item"><a className="text-white fw-bold" href="#">Pages</a></li>
                            <li className="breadcrumb-item text-white fw-bold active" aria-current="page">Products</li>
                        </ol>
                    </nav>
                </div>
            </div>
            {/* Hero End */}

            {/* Product Start */}
            <div className="container-fluid py-5">
                <div className="container">
                    <div className="row g-4">
                        <div className="col-sm-12 col-lg-6 mx-auto text-center wow fadeIn" data-wow-delay="0.1s"
                             style={{maxWidth: '600px'}}>
                            <h1 className="text-primary mb-3"><span
                                className="fw-light text-dark">Our Natural</span> Products</h1>
                            <p className=" d-flex " style={{textAlign: 'justify'}}>Using our products is simple and
                                effective. For skincare, gently apply our herbal-infused creams and serums to clean, dry
                                skin, allowing the natural ingredients to nourish and rejuvenate.</p>
                            <p className=" d-flex " style={{textAlign: 'justify'}}>
                                Each of our offerings is crafted with care, ensuring that you experience the purest form
                                of herbal goodness without harsh chemicals or additives.
                            </p>

                            <p className=" d-flex " style={{textAlign: 'justify'}}>
                                Explore the natural essence of herbs with Vibes of Herbs and discover a renewed sense of
                                well-being, naturally.
                            </p>
                            <div className="row g-4 d-flex align-items-center justify-content-center"
                                 style={{marginTop: '20px'}}>
                                <div className="col-lg-10 wow fadeIn" data-wow-delay="0.3s">
                                    <div className="row g-5">
                                        <div className="col-12 d-flex">
                                            <div className="btn-square  flex-shrink-0"
                                                 style={{width: "60px", height: "60px"}}>
                                                <img src="img/Mandala 001.png" alt="Description"
                                                     style={{width: "75%", height: " 75%", objectFit: "cover"}}></img>
                                            </div>
                                            <div className="ps-3">
                                                <span className="fw-bold d-flex " style={{textAlign: 'justify'}}>Our products are made out of 100% natural & organic materials and ingredients.</span>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex">
                                            <div className="btn-square flex-shrink-0"
                                                 style={{width: "60px", height: "60px"}}>
                                                <img src="img/Mandala 001.png" alt="Description"
                                                     style={{width: "75%", height: " 75%", objectFit: "cover"}}></img>
                                            </div>
                                            <div className="ps-3">
                                                <span className="fw-bold d-flex " style={{textAlign: 'justify'}}>Our products do not cause any internal or external side effects as the products do not include any harmful ingredients.</span>
                                            </div>
                                        </div>
                                        <div className="col-12 d-flex">
                                            <div className="btn-square  flex-shrink-0"
                                                 style={{width: "60px", height: "60px"}}>
                                                <img src="img/Mandala 001.png" alt="Description"
                                                     style={{width: "75%", height: " 75%", objectFit: "cover"}}></img>
                                            </div>
                                            <div className="ps-3">
                                                <span className="fw-bold d-flex " style={{textAlign: 'justify'}}>Our products includes 100% natural ingredients and 0% artificial ingredients.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className='col-lg-6 col-sm-12 product-img '>
                            <img className="img-product-main animated pulse infinite" src="img/productbg.png"
                                 alt=""></img>

                        </div>
                    </div>

{/*
                    <div class="row g-4">
                        <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
                            <div class="product-item text-center border h-100 p-4">
                                <div className='img-fluid2'>
                                    <img className="img-fluid mb-4 h-100 " src="img/hairwx.png" alt="" />

                                </div>
                                <div class="mb-2">
                                    <small class="fa fa-star text-primary"></small>
                                    <small class="fa fa-star text-primary"></small>
                                    <small class="fa fa-star text-primary"></small>
                                    <small class="fa fa-star text-primary"></small>
                                    <small class="fa fa-star text-primary"></small>
                                    <small>(99)</small>
                                </div>
                                <a href="" class="h6 d-inline-block mb-2">Hair Wax - 50g</a>
                                <h5 class="text-primary mb-3">LKR. 1,590/=</h5>
                                <a href="" class="h6 d-inline-block mb-2">Hair Wax - 100g</a>
                                <h5 class="text-primary mb-3">LKR. 2,990/=</h5>
                                 <a href="" class="btn btn-outline-primary px-3">Add To Cart</a>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
                            <div class="product-item text-center border h-100 p-4">
                                <div className='img-fluid2'>
                                    <img className="img-fluid mb-4 h-100 " src="img/lipbam2.png" alt="" />

                                </div>
                                <div class="mb-2">
                                    <small class="fa fa-star text-primary"></small>
                                    <small class="fa fa-star text-primary"></small>
                                    <small class="fa fa-star text-primary"></small>
                                    <small class="fa fa-star text-primary"></small>
                                    <small class="fa fa-star text-primary"></small>
                                    <small>(99)</small>
                                </div>
                                <a href="" class="h6 d-inline-block mb-2">Lip Balm - 50g</a>
                                <h5 class="text-primary mb-3">RS.99.99</h5>
                                 <a href="" class="btn btn-outline-primary px-3">Add To Cart</a>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
                            <div class="product-item text-center border h-100 p-4">
                                <div className='img-fluid2 pb-4'>
                                    <img className="img-fluid mb-4 h-100 " src="img/scrub4.png" alt="" />

                                </div>
                                <div class="mb-2">
                                <small class="fa fa-star text-primary"></small>
                                <small class="fa fa-star text-primary"></small>
                                <small class="fa fa-star text-primary"></small>
                                <small class="fa fa-star text-primary"></small>
                                <small class="fa fa-star text-primary"></small>
                                <small>(99)</small>
                            </div>
                                <a href="" class="h6 d-inline-block mb-2">Body Scrub - 100g</a>
                                <h5 class="text-primary mb-3">RS.99.99</h5>
                                 <a href="" class="btn btn-outline-primary px-3">Add To Cart</a>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
                            <div class="product-item text-center border h-100 p-4">
                                <div className='img-fluid2'>
                                    <img className="img-fluid mb-4 h-100 " src="img/lotion2.png" alt="" />

                                </div>                                    <div class="mb-2">
                                <small class="fa fa-star text-primary"></small>
                                <small class="fa fa-star text-primary"></small>
                                <small class="fa fa-star text-primary"></small>
                                <small class="fa fa-star text-primary"></small>
                                <small class="fa fa-star text-primary"></small>
                                <small>(99)</small>
                            </div>
                                <a href="" class="h6 d-inline-block mb-2">Moisturizing Lotion - 200ml</a>
                                <h5 class="text-primary mb-3">RS.99.99</h5>
                                 <a href="" class="btn btn-outline-primary px-3">Add To Cart</a>
                            </div>
                        </div>
                    </div>
*/}
                </div>
            </div>
            {/* Product End */}


        </div>

    )

}
