import React, {useRef} from "react";
import emailjs from '@emailjs/browser';


export const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_viw2eqs', 'template_zw3qs4f', form.current, {
                publicKey: 'mkIGaoJbsGCdp0JlC',
            })
            .then(
                () => {
                    alert('Send Email SUCCESS!')
                    console.log('SUCCESS!');
                },
                (error) => {
                    alert('Send Email FAILED!')

                    console.log('FAILED...', error.text);
                },
            );
        e.target.reset(e)
    };
    return (

        <div>

            {/* <!-- Hero Start --> */}
            <div className="container-fluid bg-primary hero-header-contact mb-5">
                <div className="container text-center">
                    <h1 className="display-4 text-white mb-3 animated slideInDown">Contact</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-center mb-0 animated slideInDown">
                            <li className="breadcrumb-item"><a className="text-white fw-bold" href="/">Home</a></li>
                            <li className="breadcrumb-item"><a className="text-white fw-bold" href="#">Pages</a></li>
                            <li className="breadcrumb-item text-white fw-bold active" aria-current="page">Contact</li>
                        </ol>
                    </nav>
                </div>
            </div>
            { /* <!-- Hero End -->


            <!-- Contact Info Start -->*/}
            <div className="container-fluid py-5">
                <div className="container">
                    <div className="row g-4">
                        <div className="col-lg-3 wow fadeIn" data-wow-delay="0.1s">
                            <div className="contact-info-item position-relative bg-primary text-center p-3 h-100">
                                <div className="border py-5 px-3 h-100">
                                    <i className="fa fa-map-marker-alt fa-3x text-dark mb-4"></i>
                                    <h5 className="text-white">Office Address</h5>
                                    <h6 className="fw-light text-white">"Ruwasampatha" Weliweriya, Gampaha, Sri Lanka</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 wow fadeIn " data-wow-delay="0.3s">
                            <div className="contact-info-item position-relative bg-primary text-center h-100 p-3">
                                <div className="border py-5 px-3 h-100">
                                    <i className="fa fa-phone-alt fa-3x text-dark mb-4"></i>
                                    <h5 className="text-white">Call Us</h5>
                                    <h6 className="fw-light text-white">+94 33 225 6108</h6>
                                    <h6 className="fw-light text-white">+94 777 10 2250</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 wow fadeIn "  data-wow-delay="0.3s">
                            <div className="contact-info-item position-relative bg-primary text-center p-3 h-100">
                                <div className="border py-5 px-3 h-100">
                                    <i className="fa fa-globe fa-3x text-dark mb-4"></i>
                                    <h5 className="text-white">Visit Us</h5>
                                    <a href="https://ruwasampatha.com/" target='_blank' className="h6 fw-light text-white">www.ruwasampatha.com</a>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 wow fadeIn" data-wow-delay="0.5s">
                            <div className="contact-info-item position-relative bg-primary h-100 text-center p-3">
                                <div className="border py-5 h-100 px-3">
                                    <i className="fa fa-envelope fa-3x text-dark mb-4"></i>
                                    <h5 className="text-white">Mail Us</h5>
                                    <h6 className="fw-light text-white">info@vibesofherbs.com</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*  <!-- Contact Info End -->


            <!-- Contact Start -->*/}

            <div className="container-fluid py-5">
                <div className="container">
                    <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{ maxWidth: "600px" }}>
                        <h1 className="text-primary mb-5"><span
                            className="fw-light text-dark">If You Have Any Query,</span> Please Contact Us</h1></div>
                    <div className="row g-5">
                        <div className="col-lg-7 wow fadeIn" data-wow-delay="0.1s">
                            <p className="mb-4">Have questions or need assistance? We're here to help! Reach out to us through phone, email, or visit us at our location. Your feedback is important to us.</p>

                            <div className="wow fadeIn" data-wow-delay="0.3s">
                                <form ref={form} onSubmit={sendEmail}>
                                    <div className="row g-3">
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" id="name" name='user_name'
                                                    placeholder="Your Name" />
                                                <label htmlFor="name">Your Name</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-floating">
                                                <input type="email" className="form-control" id="email" name='user_email'
                                                    placeholder="Your Email" />
                                                <label htmlFor="email">Your Email</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <input type="text" className="form-control" id="subject" name='subject'
                                                    placeholder="Subject" />
                                                <label htmlFor="subject">Subject</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-floating">
                                                <textarea className="form-control" placeholder="Leave a message here"
                                                    id="message" style={{ height: "150px" }} name='message'></textarea>
                                                <label htmlFor="message">Message</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button className="btn btn-primary w-100 py-3" type="submit">Send Message
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-5 wow fadeIn" data-wow-delay="0.5s">
                            <iframe className="w-100 h-100"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3959.891785245927!2d80.0073282736536!3d7.022004817172408!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2ffdbc6e03afd%3A0x4b3b24f5d8b5132d!2sWickramarachchi%20laboratories%20(PVT)%20Ltd!5e0!3m2!1sen!2slk!4v1719402887411!5m2!1sen!2slk"
                                frameBorder="0" style={{ minHeight: "300px", border: 0 }} allowFullScreen=""
                                aria-hidden="false"
                                tabIndex="0"></iframe>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Contact End -->


            <!-- Newsletter Start -->*/}
            {/* <div className="container-fluid newsletter bg-primary py-5 my-5">
                <div className="container py-5">
                    <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{ maxWidth: "600px" }}>
                        <h1 className="text-white mb-3"><span className="fw-light text-dark">Let's Subscribe</span> The
                            Newsletter</h1>
                        <p className="text-white mb-4">Subscribe now to get 30% discount on any of our products</p>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-7 wow fadeIn" data-wow-delay="0.5s">
                            <div className="position-relative w-100 mt-3 mb-2">
                                <input className="form-control w-100 py-4 ps-4 pe-5" type="text"
                                    placeholder="Enter Your Email"
                                    style={{ height: "48px" }} />
                                <button type="button"
                                    className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"><i
                                        className="fa fa-paper-plane text-white fs-4"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* <!-- Newsletter End --> */}


        </div>
    )
}
