import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export const Home = () => {

    return (
        <div>
            <body>

            {/* <!-- Hero Start --> */}

            <div class="container-fluid bg-primary hero-header mb-5">
                <div class="container">
                    <div class="row g-5 align-items-center">
                        <div class="col-lg-6 text-center text-lg-start">
                            <h3 class="fw-light text-white animated slideInRight">Vibes of Herbs</h3>
                            <h1 class="display-4 text-white animated slideInRight"> The <span
                                class="fw-light text-dark">Heaven</span> of Herbals </h1>
                            <p class="text-white mb-4 animated slideInRight">You've entered the heaven of herbals here,
                                namely 'Vibes of Herbs'.
                                This is your ultimate natural & organic getaway. Shop with us to shine with herbs!</p>
                            <div className='home-header-button'>
                                <a href="product" className="btn btn-dark  me-3 animated slideInRight">Shop Now</a>
                                <a href="contact" className="btn btn-outline-dark  animated slideInRight">Contact Us</a>
                            </div>

                        </div>
                        <div class="col-sm-12 col-md-12  col-lg-6">
                            <img class="img-fluid animated pulse infinite" style={{backgroundSize: "cover"}}
                                 src="img/Home23.png" alt=""></img>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Hero End --> */}


            {/* <!-- About Start --> */}
            <div class="container-fluid py-5  ">
                <div class="container">
                    <div class="row g-5 align-items-center  ">
                        <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                            <img class="img-fluid animated pulse infinite" src="img/VOH1.png"
                                 style={{width: '75%'}}></img>
                        </div>
                        <div class="col-lg-6 wow fadeIn  " data-wow-delay="0.5s">
                            <h1 class="text-primary mb-4">Why <span class="fw-light text-dark">Us?
                                </span></h1>
                            <p class="mb-4 " style={{textAlign: 'justify',}}>Founded way back in 1972, Wickramarachchi
                                Laboratories holds a leading role in providing high quality Ayurvedic
                                products all over the island being many of them renowned products. We have done a
                                massive service to the beauty & healthcare
                                sector of the country by delivering more than 2000 completely natural and organic
                                products.</p>
                            <p class="mb-4" style={{textAlign: 'justify'}}>'Vibes of Herbs' is our newly announced
                                platform where you can easily reach out for your fascinating beauty & healthcare
                                products! </p>
                            {/* <a class="btn btn-primary py-2 px-4" href="">Shop Now</a> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- About End --> */}


            {/* <!-- Feature Start --> */}
            <div class="container-fluid py-5">
                <div class="container">
                    <div class="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{maxWidth: "600px"}}>
                        <h1 class="text-primary mb-3"><span
                            class="fw-light text-dark">Ethics of Our Natural Beauty </span> & Healthcare
                            Production</h1>
                        <p class="mb-5">As an Ayurvedic products provider, we are especially concerned about the health-
                            friendly nature of our products
                            in order to give you the most natural 'Vibes of Herbs'.</p>
                    </div>
                    <div class="row g-4 align-items-center">
                        <div class="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                            <div class="row gx-lg-5 gy-lg-5 gy-5 gy-md-5 ">
                                <div class="col-12 d-flex">
                                    <div class="btn-square  flex-shrink-0"
                                         style={{width: "80px", height: "80px"}}>
                                        <img src="img/Mandala 001.png" alt="Description"
                                             style={{width: "75%", height: " 75%", objectFit: "cover"}}></img>
                                    </div>
                                    <div class="ps-3">
                                        <h5>100% Natural & Organic</h5>
                                        <hr class="w-25 bg-primary my-2"></hr>
                                        <span>Our products are made out of 100% natural & organic materials and ingredients.</span>
                                    </div>
                                </div>
                                <div class="col-12 d-flex">
                                    <div class="btn-square flex-shrink-0"
                                         style={{width: "80px", height: "80px"}}>
                                        <img src="img/Mandala 001.png" alt="Description"
                                             style={{width: "75%", height: " 75%", objectFit: "cover"}}></img>
                                    </div>
                                    <div class="ps-3">
                                        <h5>No Internal or External Side Effects </h5>
                                        <hr class="w-25 bg-primary my-2"></hr>
                                        <span>Our products do not cause any internal or external side effects as the products do not include any harmful ingredients.</span>
                                    </div>
                                </div>
                                <div class="col-12 d-flex">
                                    <div class="btn-square rounded-circle border flex-shrink-0"
                                         style={{width: "80px", height: "80px"}}>
                                        <img src="img/Mandala 001.png" alt="Description"
                                             style={{width: "75%", height: " 75%", objectFit: "cover"}}></img>
                                    </div>
                                    <div class="ps-3">
                                        <h5>No Artificial Ingredients </h5>
                                        <hr class="w-25 bg-primary my-2"></hr>
                                        <span>Our products includes 100% natural ingredients and 0% artificial ingredients.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                            <img class="img-fluid4 animated pulse infinite" src="img/fdf 1.png"></img>
                        </div>
                        <div class="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                            <div class="row gx-lg-5 gy-lg-5 gy-5  gy-md-5">
                                <div class="col-12 d-flex">
                                    <div class="btn-square  flex-shrink-0"
                                         style={{width: "80px", height: "80px"}}>
                                        <img src="img/Mandala 001.png" alt="Description"
                                             style={{width: "75%", height: " 75%", objectFit: "cover"}}></img>
                                    </div>
                                    <div class="ps-3">
                                        <h5>No Skin Irritation or Allergy </h5>
                                        <hr class="w-25 bg-primary my-2"></hr>
                                        <span>Our products so not cause any skin irritation or allergy.</span>
                                    </div>
                                </div>
                                <div class="col-12 d-flex">
                                    <div class="btn-square  flex-shrink-0"
                                         style={{width: "80px", height: "80px"}}>
                                        <img src="img/Mandala 001.png" alt="Description"
                                             style={{width: "75%", height: " 75%", objectFit: "cover"}}></img>
                                    </div>
                                    <div class="ps-3">
                                        <h5>No Artificial Smell</h5>
                                        <hr class="w-25 bg-primary my-2"></hr>
                                        <span>Our products do not cause any artificial smell.</span>
                                    </div>
                                </div>
                                <div class="col-12 d-flex">
                                    <div class="btn-square  flex-shrink-0"
                                         style={{width: "80px", height: "80px"}}>
                                        <img src="img/Mandala 001.png" alt="Description"
                                             style={{width: "75%", height: " 75%", objectFit: "cover"}}></img>
                                    </div>
                                    <div class="ps-3">
                                        <h5>100% Health-Friendly </h5>
                                        <hr class="w-25 bg-primary my-2"></hr>
                                        <span>Our products are completely healthy and cause no harm to your health or skin. </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Feature End --> */}

            {/* <!-- How To Use Start --> */}
            <div class="container-fluid how-to-use bg-primary my-5 py-5">
                <div class="container text-white py-5">
                    <div class="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{maxWidth: "600px"}}>
                        <h1 class="text-white mb-3"><span class="fw-light text-dark">How To Use Our</span> Natural &
                            Organic
                            <span class="fw-light text-dark">Products</span></h1>
                        <p class="mb-5">Tips for Using our natural & Organic products </p>
                    </div>
                    <div class="row g-5">
                        <div class="col-lg-3 text-center wow fadeIn" data-wow-delay="0.1s">
                          {/*  <div class="btn-square  mx-auto mb-4" style={{width: "150px", height: "150px"}}>
                                <img src="img/hair.png" alt="Description"
                                     style={{width: "100%", height: " 100%", objectFit: "cover"}}></img>
                            </div>*/}
                            <h5 class="text-white">Hair Wax</h5>
                            <hr class="w-25 bg-light my-2 mx-auto"></hr>
                            <span>Apply a small amount of Hair Vax onto your fingertips and massage into damp or dry hair.
                                     Style as desired to achieve a natural, nourished look with enhanced texture and shine.</span>
                        </div>
                        <div class="col-lg-3 text-center wow fadeIn" data-wow-delay="0.3s">
                           {/* <div className="btn-square  mx-auto mb-4" style={{width: "150px", height: "150px"}}>
                                <img src="img/lipbam.png" alt="Description"
                                     style={{width: "100%", height: " 100%", objectFit: "cover"}}></img>
                            </div>*/}
                            <h5 class="text-white">Lip Balm</h5>
                            <hr class="w-25 bg-light my-2 mx-auto"></hr>
                            <span>Gently apply Lip Balm to your lips whenever needed for instant hydration and a subtle sheen. </span>
                        </div>
                        <div className="col-lg-3 text-center wow fadeIn" data-wow-delay="0.5s">
                           {/* <div className="btn-square  mx-auto mb-4" style={{width: "150px", height: "150px"}}>
                                <img src="img/scrub4.png" alt="Description"
                                     style={{width: "100%", height: " 100%", objectFit: "cover"}}></img>
                            </div>*/}
                            <h5 class="text-white">Body Scrub</h5>
                            <hr class="w-25 bg-light my-2 mx-auto"></hr>
                            <span>Scoop a generous amount of Body Scrub into your hands and massage onto damp skin in circular motions.
                                     Rinse thoroughly to reveal smoother, exfoliated skin. 
                                </span>
                        </div>
                        <div class="col-lg-3 text-center wow fadeIn" data-wow-delay="0.5s">{/*
                            <div className="btn-square  mx-auto mb-4" style={{width: "150px", height: "150px"}}>
                                <img src="img/lotion52.png" alt="Description"
                                     style={{width: "100%", height: " 100%", objectFit: "cover"}}></img>
                            </div>*/}
                            <h5 class="text-white">Moisture Lotion:</h5>
                            <hr class="w-25 bg-light my-2 mx-auto"></hr>
                            <span>Smooth Moisture Lotion onto clean, dry skin after bathing or whenever your skin feels dry. Massage gently until fully
                                     absorbed to enjoy long-lasting hydration and a soft, supple feel.
                                </span>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- How To Use End --> */}

            {/* <!-- Product Start --> */}
{/*
            <div class="container-fluid py-5">
                <div class="container">
                    <div class="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{maxWidth: "600px"}}>
                        <h1 class="text-primary mb-3"><span class="fw-light text-dark">Our Best Selling </span> Vibes!
                        </h1>
                        <p class="mb-5">Here are some of our bestselling Vibes of Herbs. </p>
                    </div>
                    <div class="row g-4">
                        <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
                            <div class="product-item text-center border h-100 p-4">
                                <div className='img-fluid2'>
                                    <img className="img-fluid mb-4 h-100 " src="img/hairwx.png" alt=""/>

                                </div>

                                <div class="mb-2">
                                    <small class="fa fa-star text-primary"></small>
                                    <small class="fa fa-star text-primary"></small>
                                    <small class="fa fa-star text-primary"></small>
                                    <small class="fa fa-star text-primary"></small>
                                    <small class="fa fa-star text-primary"></small>
                                    <small>(99)</small>
                                </div>
                                <a href="" class="h6 d-inline-block mb-2">Hair Wax - 50g</a>
                                <h5 class="text-primary mb-3">LKR. 1,590/=</h5>
                                <a href="" class="h6 d-inline-block mb-2">Hair Wax - 100g</a>
                                <h5 class="text-primary mb-3">LKR. 2,990/=</h5>
                                 <a href="" class="btn btn-outline-primary px-3">Add To Cart</a>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
                            <div class="product-item text-center border h-100 p-4">
                                <div className='img-fluid2'>
                                    <img className="img-fluid mb-4 h-100 " src="img/lipbam2.png" alt=""/>

                                </div>
                                <div class="mb-2">
                                    <small class="fa fa-star text-primary"></small>
                                    <small class="fa fa-star text-primary"></small>
                                    <small class="fa fa-star text-primary"></small>
                                    <small class="fa fa-star text-primary"></small>
                                    <small class="fa fa-star text-primary"></small>
                                    <small>(99)</small>
                                </div>
                                <a href="" class="h6 d-inline-block mb-2">Lip Balm - 50g</a>
                                <h5 class="text-primary mb-3">RS.99.99</h5>
                                 <a href="" class="btn btn-outline-primary px-3">Add To Cart</a>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
                            <div class="product-item text-center border h-100 p-4">
                                <div className='img-fluid2 pb-4'>
                                    <img className="img-fluid mb-4 h-100 " src="img/scrub4.png" alt=""/>

                                </div>
                                <div class="mb-2">
                                    <small class="fa fa-star text-primary"></small>
                                    <small class="fa fa-star text-primary"></small>
                                    <small class="fa fa-star text-primary"></small>
                                    <small class="fa fa-star text-primary"></small>
                                    <small class="fa fa-star text-primary"></small>
                                    <small>(99)</small>
                                </div>
                                <a href="" class="h6 d-inline-block mb-2">Body Scrub - 100g</a>
                                <h5 class="text-primary mb-3">RS.99.99</h5>
                                 <a href="" class="btn btn-outline-primary px-3">Add To Cart</a>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
                            <div class="product-item text-center border h-100 p-4">
                                <div className='img-fluid2'>
                                    <img className="img-fluid mb-4 h-100 " src="img/lotion2.png" alt=""/>

                                </div>
                                <div class="mb-2">
                                    <small class="fa fa-star text-primary"></small>
                                    <small class="fa fa-star text-primary"></small>
                                    <small class="fa fa-star text-primary"></small>
                                    <small class="fa fa-star text-primary"></small>
                                    <small class="fa fa-star text-primary"></small>
                                    <small>(99)</small>
                                </div>
                                <a href="" class="h6 d-inline-block mb-2">Moisturizing Lotion - 200ml</a>
                                <h5 class="text-primary mb-3">RS.99.99</h5>
                                 <a href="" class="btn btn-outline-primary px-3">Add To Cart</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
*/}
            {/* <!-- Product End --> */}


            {/* <!-- Testimonial Start --> */}
{/*
            <div className="container-fluid testimonial bg-primary my-5 py-5">
                <div className="container text-white py-5">
                    <div
                        className="mx-auto text-center wow fadeIn"
                        data-wow-delay="0.1s"
                        style={{maxWidth: "600px"}}
                    >
                        <h1 className="text-white mb-3">
                            Customers Got
                            <span className="fw-light text-dark">
                                    <span style={{color: "#ffdd00"}}> Happy Vibes!</span>
                                </span>
                        </h1>
                        <p className="mb-5">
                            Our customers are all happy and these are their thoughts after shopping with Us!
                        </p>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <OwlCarousel
                                className="owl-carousel testimonial-carousel wow fadeIn"
                                data-wow-delay="0.1s"
                                items={1}
                                loop
                                autoplay
                                dots
                            >
                                <div className="testimonial-item text-center" data-dot="1">
                                    <img
                                        className="img-fluid border p-2 rounded-circle mx-auto mb-4"
                                        src={`${process.env.PUBLIC_URL}/img/testimonial-1.jpg`}
                                        alt="Testimonial 1"
                                        style={{width: '100px', height: '100px'}}
                                    />
                                    <h5 className="fw-light lh-base text-white">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis aliquet, erat non
                                        malesuada consequat, nibh erat tempus risus, vitae porttitor purus nisl vitae
                                        purus. Praesent tristique odio ut rutrum pellentesque. Fusce eget molestie est,
                                        at rutrum est. Nullam scelerisque libero nunc, vitae ullamcorper elit volutpat
                                        ut.
                                    </h5>
                                    <h5 className="mb-1">Client Name</h5>
                                    <h6 className="fw-light text-white fst-italic mb-0">Profession</h6>
                                </div>
                                <div className="testimonial-item text-center" data-dot="2">
                                    <img
                                        className="img-fluid border p-2 rounded-circle mx-auto mb-4"
                                        src={`${process.env.PUBLIC_URL}/img/testimonial-2.jpg`}
                                        alt="Testimonial 2"
                                        style={{width: '100px', height: '100px'}}
                                    />
                                    <h5 className="fw-light lh-base text-white">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis aliquet, erat non
                                        malesuada consequat, nibh erat tempus risus, vitae porttitor purus nisl vitae
                                        purus. Praesent tristique odio ut rutrum pellentesque. Fusce eget molestie est,
                                        at rutrum est. Nullam scelerisque libero nunc, vitae ullamcorper elit volutpat
                                        ut.
                                    </h5>
                                    <h5 className="mb-1">Client Name</h5>
                                    <h6 className="fw-light text-white fst-italic mb-0">Profession</h6>
                                </div>
                                <div className="testimonial-item text-center" data-dot="3">
                                    <img
                                        className="img-fluid border p-2 rounded-circle mx-auto mb-4"
                                        src={`${process.env.PUBLIC_URL}/img/testimonial-3.jpg`}
                                        alt="Testimonial 3"
                                        style={{width: '100px', height: '100px'}}
                                    />
                                    <h5 className="fw-light lh-base text-white">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis aliquet, erat non
                                        malesuada consequat, nibh erat tempus risus, vitae porttitor purus nisl vitae
                                        purus. Praesent tristique odio ut rutrum pellentesque. Fusce eget molestie est,
                                        at rutrum est. Nullam scelerisque libero nunc, vitae ullamcorper elit volutpat
                                        ut.
                                    </h5>
                                    <h5 className="mb-1">Client Name</h5>
                                    <h6 className="fw-light text-white fst-italic mb-0">Profession</h6>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div>
*/}
            {/* <!-- Testimonial End --> */}


            {/* <!-- Blog Start --> */}

            <div className="container-fluid py-5">
                <div className="container">
                    <div className="mx-auto text-center wow fadeIn" data-wow-delay="0.1s" style={{maxWidth: '600px'}}>
                        <h1 className="text-primary mb-3"><span className="fw-light text-dark">From Our</span> Blog
                            Articles</h1>
                        <p className="mb-5">Discover the secrets of nature's beauty with our 100% Ayurvedic products,
                            designed to nourish and rejuvenate your skin naturally. Embrace the power of herbs and
                            botanicals for a radiant, healthy glow.</p>
                    </div>
                    <div className="row g-4 d-flex justify-content-center">
                        <div className="col-md-7 col-lg-4 wow fadeIn" data-wow-delay="0.1s">
                            <div className="blog-item border  p-4">
                                <img className="img-fluid1 mb-4  " src="img/lipbalm.jpg" alt="Blog 1"/>
                                <a href="" className="h5 lh-base d-inline-block">Nourish & Protect Lips With Ayurvedic
                                    Lip Balm</a>
                                <div className="d-flex text-black-50 mb-2">
                                    <div className="pe-3">
                                        <small className="fa fa-eye me-1"></small>
                                        <small>9999 Views</small>
                                    </div>
                                    <div className="pe-3">
                                        <small className="fa fa-comments me-1"></small>
                                        <small>9999 Comments</small>
                                    </div>
                                </div>
                                <p className="mb-4 d-flex" style={{textAlign: "justify", fontSize: '15px'}}>
                                    Ayurvedic lip balm is a natural and holistic product formulated using traditional
                                    Ayurvedic ingredients. It typically contains herbs, essential oils, and natural
                                    butters known for their healing, moisturizing, and soothing properties
                                    .</p>
                                <a href="" className="btn btn-outline-primary px-3">Read More</a>
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                            <div className="blog-item border  p-4">
                                <img className="img-fluid1 mb-4 " src="img/bodyscrub.jpg" alt="Blog 2"/>
                                <a href="" className="h5 lh-base d-inline-block">Tried body exfoliation with an
                                    Ayurvedic body Scrub? </a>
                                <div className="d-flex text-black-50 mb-2">
                                    <div className="pe-3">
                                        <small className="fa fa-eye me-1"></small>
                                        <small>9999 Views</small>
                                    </div>
                                    <div className="pe-3">
                                        <small className="fa fa-comments me-1"></small>
                                        <small>9999 Comments</small>
                                    </div>
                                </div>
                                <p className="mb-4 d-flex" style={{textAlign: "justify", fontSize: '15px'}}>Body
                                    scrubbing is a great way to stimulate multiple senses. Body exfoliation can
                                    significantly improve the mood as the body mentally recognizes the performance of
                                    this self ritual.</p>
                                <a href="" className="btn btn-outline-primary px-3 ">Read More</a>
                            </div>
                        </div>
                        <div className="col-md-7 col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                            <div className="blog-item border  p-4">
                                <img className="img-fluid1 mb-4 " src="img/bodylotion.jpg" alt="Blog 3"/>
                                <a href="" className="h5 lh-base d-inline-block">Ayurvedic Moisturizers improve skin
                                    hydration!</a>
                                <div className="d-flex text-black-50 mb-2">
                                    <div className="pe-3">
                                        <small className="fa fa-eye me-1"></small>
                                        <small>9999 Views</small>
                                    </div>
                                    <div className="pe-3">
                                        <small className="fa fa-comments me-1"></small>
                                        <small>9999 Comments</small>
                                    </div>
                                </div>
                                <p className="mb-4 d-flex" style={{textAlign: "justify", fontSize: '15px'}}>Ayurvedic
                                    Moisturizers improves skin hydration and increases stratum corneum water content by
                                    directly providing water to the skin from their water phase and increasing occlusion
                                    to reduce trans-epidermal water loss, it also covers small skin fissures.

                                </p>
                                <a href="" className="btn btn-outline-primary px-3">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- Blog End --> */}


            </body>
        </div>
    )
}