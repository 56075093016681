import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {IoReorderThreeSharp} from "react-icons/io5";


export const Navbar = () => {
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsSticky(window.scrollY > 300);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            {/* <!-- Spinner Start --> */}

            {/* <div id="spinner" class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
                <div class="spinner-grow text-primary" style={{ width: "3rem", height: "3rem" }} role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div> */}

            {/* <!-- Spinner End --> */}

            <nav
                className={`navbar navbar-expand-lg navbar-light sticky-top ${isSticky ? 'bg-primary shadow-sm ' : ''}`}>
                <div className="container">
                    <Link to='/'>
                        <img style={{width: "90px", height: "60px", marginTop: "2px"}} src='/VOH LOGO.png' alt='Logo'/>
                    </Link>
                    <button type="button" className="navbar-toggler ms-auto me-0" data-bs-toggle="collapse"
                            data-bs-target="#navbarCollapse">
                        {/*
                        <span className="navbar-toggler-icon " ></span>
*/}
                        <IoReorderThreeSharp style={{color: '#ffffff', fontSize: '34px'}}/>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <div className="navbar-nav ms-auto">
                            <a href="/" className="nav-item nav-link text-white ">Home</a>
                            <a href="about" className="nav-item nav-link text-white">About</a>
                            <a href="history" className="nav-item nav-link text-white">Story</a>
                            <a href="product" className="nav-item nav-link text-white">Products</a>
                            {/* <a href="testimonial" className="dropdown-item">Testimonial</a> */}
                            <a href="blog" className="nav-item nav-link text-white">Blog </a>
                            <a href="contact" className="nav-item nav-link text-white">Contact</a>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
};
