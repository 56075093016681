import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Home } from './pages/Home'
import { Product } from "./pages/Product";
import { Contact } from "./pages/Contact";
import { Testimonial } from "./pages/Testimonial";
import { About } from "./pages/About";
import { Page404 } from "./pages/Page404";
import { Blog } from "./pages/Blog";
import { Navbar } from './components/NavBar';
import { Footer } from './components/Footer';
import {History} from "./pages/History";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Navbar/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product" element={<Product />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/testimonial" element={<Testimonial />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/history" element={<History />} />


          <Route path='*' element={<Page404 />} />
        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
