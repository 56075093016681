import React from 'react';
import { Footer } from '../components/Footer';
export const About = () => {
    return (
        <div>

            {/* Hero Start */}
            <div className="container-fluid bg-primary hero-header-about mb-5">
                <div className="container text-center">
                    <h1 className="display-4 text-white mb-3 animated slideInDown">About Us</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb justify-content-center mb-0 animated slideInDown">
                            <li className="breadcrumb-item"><a className="text-white fw-bold" href="/">Home</a></li>
                            <li className="breadcrumb-item"><a className="text-white fw-bold" href="#">Pages</a></li>
                            <li className="breadcrumb-item text-white active fw-bold" aria-current="page">About</li>
                        </ol>
                    </nav>
                </div>
            </div>
            {/* Hero End */}

            {/* Feature Start */}
            <div className="container-fluid py-5">
                <div className="container ">
                    <div className="row g-4">
                        <div className="col-lg-4 wow fadeIn " data-wow-delay="0.1s">
                            <div className="feature-item about-ayuruwedic position-relative  text-center p-3">
                                <div className="border py-5 px-3 bg-light" style={{ position: "relative", zIndex: "1" }}>
                                    <i className="fa fa-leaf fa-3x text-dark mb-4" style={{ position: "relative", zIndex: "2" }}></i>
                                    <h5 className="text-custom mb-0" style={{ position: "relative", zIndex: "2" }}>100% Ayurvedic</h5>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
                            <div className="feature-item position-relative about-ayuruwedic2 bg-primary text-center p-3">
                                <div className="border py-5 px-3 bg-light">
                                    <i className="fa fa-tint-slash fa-3x text-dark mb-4"></i>
                                    <h5 className="text-custom mb-0">100% Health - Friendly </h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
                            <div className="feature-item position-relative about-ayuruwedic1 bg-primary text-center p-3">
                                <div className="border py-5 px-3 bg-light">
                                    <i className="fa fa-times fa-3x text-dark mb-4"></i>
                                    <h5 className="text-custom mb-0">100% Hypoallergenic </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Feature End */}

            {/* About Start */}
            <div className="container-fluid py-5">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                            <img className="img-fluid animated pulse infinite" src="img/aboutFront.png" alt="Shampoo" />
                        </div>
                        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                            <h1 className="text-primary mb-4">'Vibes of Herbs' is Your Heavenly Getaway  <span className="fw-light text-dark">To Ayurvedic Products!</span></h1>
                            <p className="mb-4 d-flex " style={{textAlign:'justify'}}>Here's your ultimate platform to intertwine your herbal dreams with reality : we provide the most natural and
                                organic beauty, cosmetics and healthcare products in a diverse range. Our Ayurvedic products can treat you in the most natural
                                and healthy manner providing you with all your Ayurvedic beauty & healthcare solutions. </p>
                            <div className='d-flex flex-column'>
                              <h6 className="fw-bold text-primary " >Our Vision </h6>
                                <p>To be the Leading Brand for Ayurvedic Products in the Sri Lankan Market</p>
                            </div>
                            <div className='d-flex flex-column mt-1' >
                                <h6 className="fw-bold text-primary ">Our Mission </h6>
                               <p className='d-flex' style={{textAlign:'justify'}}>
                                   To Carter and Satisfy The Consumer Preferences And Expectations Through Offering Quality Beauty & Healthcare Products by Using Traditional Techniques with Research Proven Technology and Natural Herbal Essence Processed by Skilled and Experienced Workforce.
                               </p>                             </div>
                            <p className="mb-4 fw-bold text-dark" >Shop with us to get the premium quality Ayurvedic products!</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* About End */}




        </div>
    );
}
